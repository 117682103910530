import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d7a71f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "date"
}
const _hoisted_2 = {
  key: 1,
  class: "date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item default", { today: _ctx.isToday, opened: _ctx.isOpened }]),
    style: _normalizeStyle(_ctx.itemImage)
  }, [
    (_ctx.isToday)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.dateToday), 1))
      : (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.dateToday), 1))
  ], 6))
}