
import { defineComponent } from "vue";
import BaseButton from "../BaseButton.vue";

export default defineComponent({
  name: "GridItemSquare",
  components: {
    BaseButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    itemImagePath() {
      return this.data.backgroundImage ? `background-image: url('${require('@/assets/images/' + this.data.backgroundImage)}')` : '';
    }
  },
});
