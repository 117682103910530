import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-269edc90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full-width" }
const _hoisted_2 = {
  key: 0,
  class: "holder"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content-holder" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "body" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CalenderGridDivider = _resolveComponent("CalenderGridDivider")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: "page detail",
    style: _normalizeStyle(_ctx.headerImagePath)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Home' })))
      }),
      (_ctx.currentItem.content)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.logoImagePath,
              alt: "",
              class: "logo"
            }, null, 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.itemImage,
                alt: "",
                class: "cover"
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.currentItem.content.title), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.currentItem.content.body), 1),
                _createVNode(_component_BaseButton, {
                  class: "primary",
                  iconLeft: _ctx.currentItem.content.button.iconLeft,
                  iconRight: _ctx.currentItem.content.button.iconRight,
                  href: _ctx.currentItem.content.button.buttonLink
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.currentItem.content.button.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["iconLeft", "iconRight", "href"])
              ])
            ]),
            (_ctx.currentItem.content.divider)
              ? (_openBlock(), _createBlock(_component_CalenderGridDivider, {
                  key: 0,
                  copy: _ctx.currentItem.content.divider
                }, null, 8, ["copy"]))
              : _createCommentVNode("", true),
            (_ctx.currentItem.content.embed && _ctx.currentItem.content.embed.type == 'spotify')
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 1,
                  class: "embed",
                  src: _ctx.currentItem.content.embed.url,
                  width: "100%",
                  height: "380",
                  frameBorder: "0",
                  allowfullscreen: "",
                  allow: "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture",
                  loading: "lazy"
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.currentItem.content.embed && _ctx.currentItem.content.embed.type == 'youtube')
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 2,
                  class: "embed video",
                  width: "100%",
                  height: "100%",
                  src: _ctx.currentItem.content.embed.url,
                  title: "YouTube video player",
                  frameborder: "0",
                  allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }, null, 8, _hoisted_10))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Footer, { data: _ctx.footer }, null, 8, ["data"])
  ], 4))
}