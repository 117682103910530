import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9060090"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "item large",
    style: _normalizeStyle(_ctx.itemImagePath)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.data.title), 1),
      (_ctx.data.body)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.data.body), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseButton, {
        class: "primary",
        href: _ctx.data.button.href,
        iconLeft: _ctx.data.button.iconLeft,
        iconRight: _ctx.data.button.iconRight
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.data.button.cta), 1)
        ]),
        _: 1
      }, 8, ["href", "iconLeft", "iconRight"])
    ])
  ], 4))
}