
import { defineComponent } from "vue"

export default defineComponent({
  name: "CalenderOverlay",
  props: {
    data: {
      type: Object,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
  },
  computed: {
    overlayImage() {
      return require("@/assets/images/" + this.data.image)
    },
  },
})
