import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-499f0137"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isAnchor), {
    class: "button",
    href: _ctx.anchorUrl,
    target: _ctx.anchorTarget
  }, {
    default: _withCtx(() => [
      (_ctx.iconLeft)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "icon-left",
            src: require(`@/assets/images/icons/${_ctx.iconLeft}`),
            alt: ""
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.iconRight)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "icon-right",
            src: require(`@/assets/images/icons/${_ctx.iconRight}`),
            alt: ""
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["href", "target"]))
}